.social-btn {
  padding: 4px !important;
  // background-color: #a93bc0 !important;
  color: white !important;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  margin: 5px 0px 5px 20px;
}

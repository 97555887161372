.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .controllerContainer {
    bottom: 10%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 375px;
    background-color: transparent;
    z-index: 200;
    display: flex;
    @media screen and (min-width: 480px) {
      width: 800px;
    }

    .line {
      size: "8";
      width: 30%;
      height: 2px;
      border: #a93bc0;
      margin: 10px 30px;
      border-style: solid;
      border-width: 1px;

      @media screen and (max-width: 480px) {
        margin: 10px 5px;
        width: 5%;
      }
    }
    .controller {
      flex: 1;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      color: white;
      display: flex;
    }
    .navItem {
      font-family: Exo;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      font-size: 16px;
      margin: 0;
      color: #ffffff;
    }

    .next {
      font-family: Exo;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
    }

    .back {
      opacity: 0.3;
      font-family: Exo;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      color: #ffffff;
    }
  }

  .contentContainer {
    top: 30%;
    left: 130px;
    max-width: 90%;
    position: absolute;
    @media screen and (max-width: 480px) {
      top: 40%;
      left: 5%;
    }
    @media screen and (min-width: 586px) {
      max-width: 650px;
    }
  }
  .title {
    font-family: Exo;
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 110%;
    /* or 79px */

    letter-spacing: 0.02em;
    color: #ffffff;

    @media screen and (max-width: 480px) {
      font-size: 50px;
      line-height: 100%;
    }
  }

  .subTitle {
    font-family: Exo;
    font-style: bold;
    font-weight: bold;
    font-size: 72px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #ffffff;

    @media screen and (max-width: 480px) {
      font-size: 50px;
      line-height: 100%;
    }
  }

  .desc {
    min-height: 50px;
    font-family: Exo;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    color: #ffffff;
    @media screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 130%;
    }
  }

  .desc2 {
    min-height: 50px;
    font-family: Exo;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 36px */

    color: white;
    @media screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 130%;
    }
  }

  .youtubeText {
    font-family: Exo;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    margin: 12px 0px;
    /* identical to box height, or 20px */

    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .button {
    background-color: #5639ed;
    border-radius: 8px;
    font-size: 16px;
    line-height: 23px;
    color: #fff;
    cursor: pointer;
    margin: 40px 0;
  }
  .socials {
    margin-top: 0;
    margin-right: 12px;
    align-self: center;
    font-size: 27px;
    margin-bottom: 5px;
  }
}

.appAlert {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  @media screen and (max-width: 480px) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
}

.iconAlert {
  width: 16px;
  height: 16px;
}

.alertText {
  max-width: 409px;
  height: 30px;
  font-family: Exo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 30px */

  text-transform: uppercase;

  color: #ffffff;

  opacity: 0.5;
  margin: 0px 16px;
  display: flex;
  align-items: center;
}

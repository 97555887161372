.loadingButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 120px;
  width: 307px;
  height: 45px;
  border: 1.5px solid #a93bc0;
  box-sizing: border-box;
  border-radius: 100px;

  /* Inside Auto Layout */
  margin: 48px 0px;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructionContainer {
  text-align: center;
  font-weight: normal;
  font-size: 32px;
  line-height: 35.2px;
  color: #fff;
  margin: 30px;
}

.connectWalletContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 28px;
  line-height: 35.2px;
  color: #fff;
}

.wrapper {
  width: 100%;
  max-width: 600px;
  height: 100%;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #222;
}

.form {
  margin: 10px auto;
  border: none;
  padding: 20px;
  border-radius: 3px;

  .PhoneInputCountry {
    width: 100px;
  }
  & div {
    display: flex;
    flex-flow: row nowrap;
    line-height: 2em;
    // margin: 10px;
    & label {
      font-family: "Exo";
      color: white;
      width: 200px;
      font-size: 16px;
      margin-right: 10px;
      font-weight: normal;
    }
    & ::placeholder {
      font-size: 0.8rem;
      opacity: 0.8;
    }
    & input,
    & select,
    & textarea {
      font-family: "Exo";
      font-size: 16px;
      font-weight: normal;
      flex: 1;
      padding: 3px 5px;
      color: white;
      border: 1px solid #ccc;
      border-radius: 3px;
      background-color: hsla(0, 0%, 100%, 0.2);
    }
    & input[type="checkbox"] {
      margin-top: 7px;
    }
  }
  .burn-country-select {
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: hsla(0, 0%, 100%, 0.2);
  }
}

.container {
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 120px 0px 100px 0px;

  @media screen and (max-width: 960px) {
    padding: 20px 0px 160px 0px;
  }

  .upper {
    margin-bottom: 80px;

    .headline {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 40px;

      .content {
        font-weight: bold;
        font-size: 48px;
        line-height: 52.8px;
        color: #fff;
        @media screen and (max-width: 960px) {
          font-size: 32px;
          line-height: 35.2px;
        }
      }
    }
  }

  .nftList {
    display: flex;
    flex-wrap: wrap;
    
    margin: 0px 50px;
    // padding: 0 100px;
    justify-content: center;
  
    @media screen and (max-width: 540px) {
      padding: 0;
      margin-top: 50px !important;
    }
  
  }
}

.loading {
  font-family: Exo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-transform: uppercase;

  /* Main/Dark/White */
  color: #fcfcfd;
  margin: 0px 10px;
}

.loadingButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 120px;

  width: 307px;
  height: 45px;

  border: 1.5px solid #a93bc0;
  box-sizing: border-box;
  border-radius: 100px;

  /* Inside Auto Layout */
  margin: 48px 0px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rewardButton {
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 12px 24px;

  width: 222px;
  height: 48px;

  background: white;
  border-width: 0;
  border-radius: 16px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;

  font-family: Exo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-transform: uppercase;

  /* Main/Dark/White */

  color: #a93bc0;
  cursor: pointer;
}
.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
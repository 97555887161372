.container {
  max-width: 1440px;
  // min-height: 100vh;
  margin: 0 auto;
  padding: 100px 0px 20px 0px;

  @media screen and (max-width: 960px) {
    padding: 20px 0px 20px 0px;
  }

  .upper {
    margin-bottom: 50px;

    .headline {
      display: flex;
      text-align: center;
      flex-direction: column;
      margin-bottom: 40px;

      .content {
        font-weight: bold;
        font-size: 48px;
        line-height: 52.8px;
        color: #fff;
        @media screen and (max-width: 960px) {
          font-size: 32px;
          line-height: 35.2px;
        }
      }
    }
  }
}


.burnNFTButton {
  align-self: center;
  background-color: #a93bc0 !important;
  min-width: 300px;
  opacity: 1;
  height: 50px !important;
  color: #fff;
  border: 0;
  line-height: 1.75;
  border-radius: 16px;
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 45px;
  cursor:pointer;
  
  .burnNFTButtonText {
    font-weight: bold;
    font-size: 16px;
    font-family: "Exo2";
    text-transform: uppercase;
  }

  .burnDesc {
    color: white;
    font-family: Exo;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;

  }
}
